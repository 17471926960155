<div>
  <div class="text-xl">Planned Outages</div>
  <div class="mt-20 date-range-selectors flex gap-x-24 items-baseline">
    <app-date-selector dpLabel="From" [isFromDate]="true" [minDate]="minDatePickerFromDate"
      [maxDate]="maxDatePickerFromDate" (dateChanged)="setFromDate($event)">
    </app-date-selector>
    <app-date-selector dpLabel="Thru" [isFromDate]="false" [minDate]="minDatepickerThruDate"
      [maxDate]="maxDatepickerThruDate" (dateChanged)="setThruDate($event)">
    </app-date-selector>
    <button class="de-button m-0" (click)="getDataForMonthRange()">apply</button>
    <div>
      <a [routerLink]="['/action-center/planned-outages/new']" class="ml-24 strong" (click)="
          GtmService.clickEvent({ category: 'operations', action: 'clickthrough', label: 'new-planned-outage' });
        ">
        <img class="inline mr-6" src="assets/icons/plus.svg" alt="" />
        <span class="underline">New Outage</span>
      </a>
    </div>
  </div>
  <ng-container *ngIf="dataSource.data?.length > 0; else noData">
    <app-table-with-filters tableExportTitle="planned-outages" tableType="reporting" [isSavingFiltersEnabled]="false"
      [columns]="columns" [tableData]="dataSource.data" [totalsRowType]="'none'">
      <ng-template #actionTemplate let-element="element" let-column="column">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editPlannedOutage(element.uuid)">
            <span>Edit Outage</span>
          </button>
        </mat-menu>
      </ng-template>
      <app-data-cell name="action" label="" [cellTemplate]="actionTemplate"> </app-data-cell></app-table-with-filters>
  </ng-container>
  <ng-template #noData>
    <app-no-data [title]="'No Planned Outages'" [summary]="'Planned Outages Data will appear here'"> </app-no-data>
  </ng-template>
</div>
