import { InterconnectionModel } from 'src/app/shared/models/interconnection';
import { JurisdictionShortCode } from 'src/app/shared/models/jurisdiction';
import { Address } from 'src/app/shared/models/address';

import { SiteNotification } from './sites/siteNotification';
import { SiteContract } from './sites/siteContract';
import { SiteNotificationDetail } from './sites/siteNotificationDetail';
import { ConnectionType } from 'src/app/shared/models/enums/connectionType';
import { EnergyType } from 'src/app/shared/models/enums/energyType';
import { SiteClassifier } from './enums/siteClassifier';
import {
  NotificationDetailsEnum,
  NotificationDisplaySpec,
  NotificationDisplaySpecs,
} from './siteFlagDetails';
import { USState } from './enums/usState';

export interface YTDCurtailment {
  count?: number;
  minutes?: number;
  remaining?: number;
  used?: number;
  usedEconomic?: number;
  usedReliability?: number;
  usedUncertain?: number;
}

export interface MostRecentCurtailment {
  amount?: number;
  date?: string;
  duration?: number;
  reason?: string;
}

export class Site {
  sapAccountNumber?: string;
  unique_id?: number;
  uniqueId?: number;
  uniqueIdDisplay?: string;
  address?: Address;
  connectionType?: ConnectionType;
  distribution_or_transmission?: ConnectionType;
  energyType?: EnergyType;
  batteryType?: EnergyType;
  notificationTypes?: NotificationDetailsEnum[];
  notificationDisplaySpecs?: NotificationDisplaySpec[];
  name: string;
  substationName?: string;
  jurisdiction: JurisdictionShortCode;
  ownedBy?: string;
  opCenterClassifier?: SiteClassifier;

  breakerStatus?: string;
  recloserStatus?: string;

  // TODO: refactor to siteContract
  operationalDate?: Date;
  commercialOperationalDate?: Date;
  disFid?: string;
  feederNumber?: string;

  // TODO: Validate these
  capacity?: number;
  ownershipStatus?: string;

  contract: SiteContract;
  mostRecentCurtailment?: MostRecentCurtailment;
  ytdCurtailment?: YTDCurtailment;

  notifications: SiteNotification[];
  notificationDetails: SiteNotificationDetail[];

  static fromInterconnection(interconnection: InterconnectionModel): Site {
    const ret: Site = {
      // TODO: Remove direct mapping
      ...interconnection,
      sapAccountNumber: interconnection.sap_account_number,
      feederNumber: interconnection.feeder_number,
      disFid: interconnection.dis_fid,
      address: {
        street: interconnection.street_address,
        city: interconnection.city,
        state: interconnection.facility_state as USState,
        zipCode: interconnection.zip_code,
        county: interconnection.county,
      },
      breakerStatus: interconnection.breaker_status,
      energyType:
        interconnection.energy_source === 'Solar'
          ? EnergyType.Solar
          : interconnection.energy_source == 'Microgrid'
          ? EnergyType.Microgrid
          : EnergyType.Battery,
      batteryType:
        interconnection.battery_type === 'Battery'
          ? EnergyType.Battery
          : interconnection.battery_type == 'Microgrid'
          ? EnergyType.Microgrid
          : EnergyType.Solar,
      commercialOperationalDate: interconnection.commercial_operational_date,
      connectionType: interconnection.distribution_or_transmission as
        | ConnectionType.Distribution
        | ConnectionType.Transmission,
      distribution_or_transmission: interconnection.distribution_or_transmission as
        | ConnectionType.Distribution
        | ConnectionType.Transmission,
      capacity: interconnection.facility_installed_capacity_mw,
      contract: new SiteContract(interconnection),
      mostRecentCurtailment: {
        amount: interconnection?.most_recent_curtailment_amount,
        date: interconnection?.most_recent_curtailment_date,
        duration: interconnection?.most_recent_curtailment_duration,
        reason: interconnection?.most_recent_curtailment_reason,
      },
      name: interconnection.customer_legal_name,
      notificationTypes: interconnection.notification_flags as NotificationDetailsEnum[],
      notificationDisplaySpecs: this.ensureArray(
        NotificationDisplaySpecs.getNotificationDisplaySpecsFromNotificationTypes(
          interconnection.notification_flags as NotificationDetailsEnum[]
        )
      ),
      notificationDetails: interconnection.notification_details?.map(
        (n) => new SiteNotificationDetail(n)
      ),
      notifications: interconnection.site_notifications?.map((n) => new SiteNotification(n)),
      opCenterClassifier: interconnection.op_center_classifier as
        | SiteClassifier.DGOC
        | SiteClassifier.RROC,
      operationalDate: interconnection.operational_date,
      ownedBy: interconnection.project_designation == 'Utility Owned' ? 'Duke Energy' : 'External',
      ownershipStatus: interconnection.project_designation,
      recloserStatus: interconnection.recloser_status,
      substationName: interconnection.substation_name,
      uniqueId: interconnection.unique_id,
      ytdCurtailment: interconnection.ytd_curtailment_used
        ? {
            count: interconnection.ytd_curtailment_count,
            minutes: interconnection.ytd_curtailment_minutes,
            remaining: interconnection.ytd_curtailment_remaining,
            used: interconnection.ytd_curtailment_used,
            usedEconomic: interconnection.ytd_curtailment_used_economic,
            usedReliability: interconnection.ytd_curtailment_used_reliability,
            usedUncertain: interconnection.ytd_curtailment_used_uncertain,
          }
        : null,
    };
    if (ret.unique_id) {
      ret.uniqueIdDisplay = `${ret.unique_id}`.padStart(6, '0');
    }
    return ret;
  }

  static ensureArray<T>(value: T | T[]): T[] {
    return Array.isArray(value) ? value : [value];
  }
}
