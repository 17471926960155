<div class="date-selector__container flex">
<mat-form-field appearance="outline">
    <mat-label>{{dpLabel}}</mat-label>
    <input matInput
           readonly
           [formControl]="dateControl"
           [matDatepicker]="dp"
           [min]="minDate"
           [max]="maxDate">
    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp
                    startView="multi-year"
                    panelClass="example-month-picker"
                    (monthSelected)="setAndFormatDate($event, dp)">
    </mat-datepicker>
</mat-form-field>
</div>
