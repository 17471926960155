import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import MessageTypeData from '../../../../assets/testing/message-type.json';
import { ApiService } from '../api/api.service';
import { OutboxMessageType } from '../../models/outboxMessageType';

@Injectable({
  providedIn: 'root',
})
export class LookupService extends ApiService {
  public messageTypeData = MessageTypeData;
  public loadingOutboxMessageType$ = new BehaviorSubject(false);

  getNewNotificationTypes(): Observable<OutboxMessageType[]> {
    this.loadingOutboxMessageType$.next(true);

    return of(this.messageTypeData).pipe(
      finalize(() => this.loadingOutboxMessageType$.next(false))
    );
  }
}
