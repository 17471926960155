import { UserInfo, UserInfoApiModel } from '../userInfo';

export class ChangeRequest {
  requestedBy: UserInfo;
  requestDate: string;
  requestReason: string;

  constructor(apiModel?: ChangeRequestModel) {
    if (!apiModel) {
      this.requestedBy = new UserInfo('', '', '', '');
      this.requestDate = '';
      this.requestReason = '';
      return;
    }

    this.requestedBy = UserInfo.fromApiModel(apiModel.requested_by);
    this.requestDate = apiModel.request_date;
    this.requestReason = apiModel.request_reason;
  }
}

export class ChangeRequestModel {
  requested_by: UserInfoApiModel;
  request_date: string;
  request_reason: string;

  constructor(source: ChangeRequest) {
    this.requested_by = new UserInfoApiModel(source.requestedBy);
    this.request_date = source.requestDate;
    this.request_reason = source.requestReason;
  }
}
