import { InterconnectionModel } from 'src/app/shared/models/interconnection';
import { Amendment } from './amendment';

export class SiteContract {
  billingAcctNum: string;
  lengthOfContract: string;
  timeRemaining: string;
  curtailableAmount: number;
  curtailablePercent: number;
  capacity: number;
  ptoAtFullOutputDate: Date;
  operationalDate: Date;
  contractStartDate: Date;
  contractEndDate: Date;
  agreementType: string;
  ppaType: string;
  contractStatus: string;
  negotiatedType: string;
  amendments?: Amendment[];
  parasiticAccountNumber: string;

  constructor(data: InterconnectionModel) {
    const term = data.contract_term?.split(' ');
    const termLength = term?.length > 1 ? term[0] : null;

    this.billingAcctNum = data.billing_account_number;
    this.operationalDate = data.operational_date;
    this.contractStartDate = data.contract_date;
    this.contractEndDate = data.expiration_date;
    this.ppaType = data.ppa_type;
    this.agreementType = data.cpre_participant_display_flag;
    this.lengthOfContract = this.getContractTerm(data.contract_term, termLength);
    this.timeRemaining = this.getTimeRemaining(this.contractEndDate);
    this.curtailableAmount = data.allowable_curtailment_mwh;
    this.curtailablePercent = data.allowable_curtailment_percent;
    this.capacity = data.facility_installed_capacity_mw;
    this.ptoAtFullOutputDate = data.permission_to_operate_at_full_output;
    this.contractStatus = data.contract_status;
    this.amendments = data.amendments ? data.amendments.map((x) => new Amendment(x)) : [];
    this.negotiatedType = data.negotiated_type;
  }

  private getContractTerm(contractTerm: string, termLength: string | null): string {
    if (termLength) {
      return `${termLength} Years`;
    } else if (contractTerm) {
      return contractTerm;
    } else {
      return 'Unknown';
    }
  }

  /**
   * Calculates and returns the remaining time on a contract.
   *
   * @param contractStartDate - The start date of the contract, can be null. Ignored if contractEndDate passed.
   * @param contractEndDate - The end date of the contract, can be null.
   * @param termInYears - The term of the contract in years, can be null. Ignored if contractEndDate passed.
   *
   * @returns The remaining time as a string in format 'x years y months', 'Expired', or 'Unknown'.
   */
  public getTimeRemaining(contractEndDate?: Date): string {
    const today = new Date();

    if (contractEndDate) {
      const endDate = new Date(contractEndDate);
      if (today > endDate) {
        return 'Expired';
      } else {
        return this.diffYearsMonths(today, endDate);
      }
    }

    return 'Unknown';
  }

  private diffYearsMonths = (earlierDate: Date, laterDate: Date): string => {
    let months = laterDate.getMonth() - earlierDate.getMonth();
    let years = laterDate.getFullYear() - earlierDate.getFullYear();

    if (months < 0) {
      years--;
      months += 12;
    }

    const yearsString = years > 0 ? `${years} Years` : '';
    const monthsString = months > 0 ? `${months} Months` : '';

    // Return null if both years and months are zero
    if (years === 0 && months === 0) {
      return null;
    }

    return `${yearsString} ${monthsString}`.trim();
  };
}
