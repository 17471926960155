import { ReviewRecord, ReviewRecordModel } from '../approvals/reviewRecord';
import { ApprovalStatus } from '../enums/approvalStatus';
import { UserInfoApiModel } from '../userInfo';
import { PlannedOutageModel } from './plannedOutage';
import { ChangeRequest, ChangeRequestModel } from '../approvals/changeRequest';

export class OutageRequest {
  // Specific to a revision
  uuid: string;
  originalOutageUUID: string;
  approvalStatus: ApprovalStatus;
  requestDate: Date;
  reviewedDate: Date;
  rejectReason?: string;

  // Request/approval workflow details
  approvalDetails: ReviewRecord;
  changeRequest: ChangeRequest;

  // Fields specific to an outage
  uniqueId: number;
  outageEventType: string;
  installedCapacity: number;
  expectedCapacity: number;
  additionalComments: string;
  derateAmount: number;
  jurisdiction: string;
  eventStart: Date;
  eventEnd: Date;

  // Old values of fields
  outageEventTypeOld?: string;
  additionalCommentsOld?: string;
  derateAmountOld?: number;
  expectedCapacityOld?: number;
  eventStartOld?: Date;
  eventEndOld?: Date;

  // Generated for table view
  siteNameDisplay: string;
  action: string;
  siteRoute?: string;
  approvals: ReviewRecord[];
  distributionOrTransmission: string;
  dropRetap: boolean;
  dropDate: Date;
  retapDate: Date;
  switchingNeeded: boolean;

  constructor(model: OutageRequestModel) {
    this.uuid = model.uuid;
    this.originalOutageUUID = model.original_outage_uuid;
    this.approvalStatus = model.approval_status;
    this.requestDate = model.request_date ? new Date(model.request_date) : null;
    this.reviewedDate = model.processed_date ? new Date(model.processed_date) : null;
    this.approvalDetails =
      model.processed_by || model.reason
        ? new ReviewRecord({
            processed_by: model.processed_by,
            processed_date: model.processed_date,
            reason: model.reason,
          })
        : null;
    this.rejectReason =
      this.approvalStatus !== ApprovalStatus.Approved ? this.approvalDetails?.rejectReason : '';
    this.changeRequest = new ChangeRequest(model.change_request);

    this.uniqueId = model.unique_id;
    this.siteNameDisplay = model.site_name;
    this.siteRoute = `/sites/${model.unique_id}`;
    this.outageEventType = model.event_type ?? model.outage_type + ' Outage';
    this.installedCapacity = model.installed_capacity;
    this.expectedCapacity = model.expected_capacity;
    this.additionalComments = model.additional_comments;
    this.derateAmount = model.derate_amount ?? this.installedCapacity - this.expectedCapacity;
    this.jurisdiction = model.jurisdiction;
    this.eventStart = model.event_start;
    this.eventEnd = model.event_end;
    this.action = ``;
    this.approvals = (model.approvals || []).map((apiApproval) => new ReviewRecord(apiApproval));
    this.distributionOrTransmission = model.distribution_or_transmission;
    this.dropRetap = model.drop_retap;
    this.dropDate = model.drop_date;
    this.retapDate = model.retap_date;
    this.switchingNeeded = model.switching_needed;
  }

  setCurrentOutageValues(outage: PlannedOutageModel) {
    this.outageEventTypeOld = outage.event_type !== this.outageEventType ? outage.event_type : null;

    this.additionalCommentsOld =
      outage.additional_comments !== this.additionalComments ? outage.additional_comments : null;

    this.derateAmountOld = outage.derate_amount !== this.derateAmount ? outage.derate_amount : null;

    this.expectedCapacityOld =
      outage.expected_capacity !== this.expectedCapacity ? outage.expected_capacity : null;

    this.eventStartOld =
      outage.event_start !== this.eventStart.toString() ? new Date(outage.event_start) : null;

    this.eventEndOld =
      outage?.event_end !== this.eventEnd.toString() ? new Date(outage?.event_end) : null;
  }
}

export class OutageRequestModel {
  // Specific to a revision
  uuid: string;
  original_outage_uuid: string;
  approval_status: ApprovalStatus;
  request_date: Date;
  processed_date: string;
  processed_by: UserInfoApiModel;
  reason: string;

  // Request/approval workflow details
  change_request: ChangeRequestModel;

  // Fields specific to an outage
  unique_id: number;
  site_name: string;
  event_type: string;
  outage_type: string;
  installed_capacity: number;
  expected_capacity: number;
  additional_comments: string;
  derate_amount: number;
  jurisdiction: string;
  event_start: Date;
  event_end: Date;
  approvals: ReviewRecordModel[];
  distribution_or_transmission: string;
  drop_retap: boolean;
  drop_date: Date;
  retap_date: Date;
  switching_needed: boolean;
}
