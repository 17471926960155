import { Component, EventEmitter, Input, Output, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDatePickerDateFromString } from '../../utils/date-change.converter';
import { addDays, isAfter, isBefore } from 'date-fns';

import { debounceTime, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';

// TODO: This can likely replace the date-selector, and can provide @Input() options for the extra controls
/**
 * Provides a previous day, next day, and today controls, with min/max validation
 */
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatepickerComponent extends BaseComponent implements OnInit {
  @Input() label = 'Selected Date';
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() selectedDate?: Date = new Date();
  @Input() displayToday? = true;
  @Input() appearance? = '';

  @Output() dateChange = new EventEmitter<Date>();

  public dateForm: FormGroup;

  private _datepickerValue = '';
  private _debouncer: Subject<Date> = new Subject<Date>();

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.dateForm = this.formBuilder.group({
      date: [this.selectedDate ? this.selectedDate : new Date(), Validators.required],
    });
    this.subscriptions.push(
      this._debouncer
        .pipe(
          debounceTime(500),
          tap((d) => {
            this.dateChange.emit(d);
          })
        )
        .subscribe()
    );
  }

  /**
   * Helps to support manual date input. On manual input change only.
   * @param datepickerInput
   */
  public saveDateValue(datepickerInput: EventTarget): void {
    this._datepickerValue = (datepickerInput as HTMLInputElement).value;
  }

  /**
   * Occurs on focusOut of the input field or when a date is selected via UI
   * @param datepickerEvent
   */
  public dateChanged(datepickerEvent: MatDatepickerInputEvent<Date>): void {
    if (datepickerEvent.value && this.dateForm.valid) {
      this.dateChange.emit(datepickerEvent.value);
    }
  }

  /**
   * Helps to support manual date input. On focusOut.
   */
  public formatDate(): void {
    formatDatePickerDateFromString(this.dateForm.get('date'), this._datepickerValue);
  }

  public nextDay(): void {
    const newDate = addDays(this.dateForm.value.date as Date, 1);
    if (isBefore(newDate, addDays(this.maxDate, 1))) {
      this.dateForm.setValue({ date: newDate });
      this._debouncer.next(newDate);
    }
  }

  public previousDay(): void {
    const newDate = addDays(this.dateForm.value.date as Date, -1);
    if (isAfter(newDate, addDays(this.minDate, -1))) {
      this.dateForm.setValue({ date: newDate });
      this._debouncer.next(newDate);
    }
  }

  public today(): void {
    const newDate = new Date();
    this.dateForm.setValue({ date: newDate });
    this._debouncer.next(newDate);
  }
}
