import { UserInfo, UserInfoApiModel } from '../userInfo';
import { ApprovalRoles, ApprovalStatus } from './approvalStatus';

export class ReviewRecord {
  reviewedBy: UserInfo;
  reviewedDate?: Date;
  rejectReason?: string | null;
  role?: ApprovalRoles;
  status: ApprovalStatus;

  constructor(api: ReviewRecordApi) {
    const userApi = api.processed_by || api.approved_by || api.reviewed_by;
    this.reviewedBy = userApi ? UserInfo.fromApiModel(userApi) : new UserInfo('', '', '', '');
    this.reviewedDate = api.processed_date
      ? new Date(api.processed_date)
      : api.approved_date
      ? new Date(api.approved_date)
      : api.reviewed_date
      ? new Date(api.reviewed_date)
      : null;
    this.rejectReason = api.reason || api.reject_reason || '';
    this.role = api?.role ? (api?.role as ApprovalRoles) : undefined;
    this.status = api.status ?? ApprovalStatus.Pending;
  }
}

export interface ReviewRecordApi {
  processed_by?: UserInfoApiModel;
  processed_date?: string;
  reason?: string | null;

  approved_by?: UserInfoApiModel;
  approved_date?: string;
  role?: string;
  status?: ApprovalStatus;

  reviewed_by?: UserInfoApiModel;
  reviewed_date?: string;
  reject_reason?: string;
}

export class ReviewRecordModel {
  reason?: string | null;
  approved_by?: UserInfoApiModel;
  role: ApprovalRoles;
  approved_date?: string;
  status: ApprovalStatus;

  constructor(record: ReviewRecord) {
    this.reason = record.rejectReason ?? null;
    this.approved_by = record.reviewedBy ? new UserInfoApiModel(record.reviewedBy) : undefined;
    this.role = record.role;
    this.approved_date = record.reviewedDate ? new Date(record.reviewedDate).toISOString() : null; // or parse to date if needed
    this.status = record.status;
  }
}
