export enum NotificationDetailsEnum {
  // These need to match what the backend has exactly in NotificationType
  ALL = 'all',
  ACCOUNT_STATUS_CHANGE = 'account_status_change',
  BREAKER_STATUS_CHANGE = 'breaker_status_change',
  COMMERCIAL_OPERATIONAL_DATE = 'commercial_operational_date',
  COMM_STATUS_CHANGE = 'comm_status_change',
  DISCONNECT_NOTICE = 'disconnect_notice',
  NO_GENERATION = 'no_generation',
  OPERATIONAL_DATE = 'operational_date',
  OVER_GENERATION = 'over_generation',
  PAYMENT_OVERDUE = 'payment_overdue',
  PTO_AT_FULL_OUTPUT = 'pto_at_full_output',
  RECLOSER_STATUS_CHANGE = 'recloser_status_change',
  VENDOR_CHANGE = 'vendor_change',
  SETPOINT_COMPLIANCE = 'setpoint_compliance',
  USER_CREATED_MESSAGE = 'user_created_message',
}

export enum NotificationDescriptionEnum {
  AccountStatusChange = 'Alert created when the account status changes in 7 days.',
  BreakerStatusChange = 'Any change from tripped to closed or vice versa is an alert. Alert remains active for 24 hours. Older alerts can be viewed in Messages tab.',
  CommercialOperationalDate = 'Alert 30 days before and turn off 7 days after COD is declared.',
  CommStatusChange = 'Status change from online, failed, or unknown. Alert remains active for 24 hours. Older alerts can be viewed in Messages tab.',
  DisconnectNotice = 'When the notice has been issued based on the delinquency information.',
  NoGeneration = 'Alert created when the site is not generating for at least 24 hours.',
  OperationalDate = 'Alert 30 days before and turn off 7 days after OD is declared.',
  OverGeneration = 'Average hourly generation is compared to the PPA installed site capacity. Alert remains active for 24 hours. Older alerts can be viewed in Messages tab.',
  PaymentOverdue = 'Alert generated when the Payment is overdue.',
  PTOAtFullOutput = 'Alert 30 days before and turn off 7 days after COD is declared.',
  RecloserStatusChange = 'Any change from open to closed or vice versa is an alert. Alert remains active for 24 hours. Older alerts can be viewed in Messages tab.',
  SetPointCompliance = 'Within 10 minutes of external basepoint being set, if setpoint feedback doesn`t go within a 5% threshold of that basepoint, it`s flagged.',
  VendorChange = 'When Invoice ID, Work ID or Payment Type, Address sequence and Vendor ID has changed in SAP, CAPS.',
  UserCreatedMessage = 'This message was generated by an internal user and sent to customer sites listed.',
}

export class NotificationDisplaySpec {
  public key: string;
  public displayName: string;
  public programManagerDisplayName: string;
  public description: string;
  public iconUrl: string;
  public active: boolean;
  public programManagerToggleButton: boolean;
  public notificationPreferenceOptions: boolean;

  constructor(data: NotificationDisplaySpec) {
    this.key = data.key;
    this.displayName = data.displayName;
    this.programManagerDisplayName = data.programManagerDisplayName;
    this.description = data.description;
    this.iconUrl = 'app/shared/assets/icons/site-flags/' + data.iconUrl;
    this.active = data.active;
    this.programManagerToggleButton = data.programManagerToggleButton;
    this.notificationPreferenceOptions = data.notificationPreferenceOptions;
  }
}

export class NotificationDisplaySpecs {
  static all: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.ALL,
    displayName: 'All',
    programManagerDisplayName: 'All',
    description: 'All',
    iconUrl: 'all.svg',
    active: true,
    programManagerToggleButton: true,
    notificationPreferenceOptions: false,
  });

  static accountStatusChange: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.ACCOUNT_STATUS_CHANGE,
    displayName: 'Account Status',
    programManagerDisplayName: 'Account Status',
    description: NotificationDescriptionEnum.AccountStatusChange,
    iconUrl: 'account-status.svg',
    active: true,
    programManagerToggleButton: false,
    notificationPreferenceOptions: false,
  });
  static breakerStatusChange: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.BREAKER_STATUS_CHANGE,
    displayName: 'Breaker Status Change',
    programManagerDisplayName: 'Breaker Tripped',
    description: NotificationDescriptionEnum.BreakerStatusChange,
    iconUrl: 'letter-b.svg',
    active: true,
    programManagerToggleButton: true,
    notificationPreferenceOptions: true,
  });
  static commercialOperationalDate: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.COMMERCIAL_OPERATIONAL_DATE,
    displayName: 'Commercial Operational Date',
    programManagerDisplayName: 'Commercial Operational Date',
    description: NotificationDescriptionEnum.CommercialOperationalDate,
    iconUrl: 'commercial-operational-date.svg',
    active: true,
    programManagerToggleButton: false,
    notificationPreferenceOptions: false,
  });
  static commStatusChange: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.COMM_STATUS_CHANGE,
    displayName: 'Comm Status Change',
    programManagerDisplayName: 'Comm Status Change',
    description: NotificationDescriptionEnum.CommStatusChange,
    iconUrl: 'no-comms.svg',
    active: true,
    programManagerToggleButton: false,
    notificationPreferenceOptions: false,
  });
  static disconnectNotice: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.DISCONNECT_NOTICE,
    displayName: 'Disconnect Notice',
    programManagerDisplayName: 'Disconnect Notice',
    description: NotificationDescriptionEnum.DisconnectNotice,
    iconUrl: 'disconnect-notice.svg',
    active: true,
    programManagerToggleButton: true,
    notificationPreferenceOptions: true,
  });
  static noGeneration: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.NO_GENERATION,
    displayName: 'No Generation',
    programManagerDisplayName: 'No Generation',
    description: NotificationDescriptionEnum.NoGeneration,
    iconUrl: 'no-generation.svg',
    active: true,
    programManagerToggleButton: true,
    notificationPreferenceOptions: true,
  });
  static operationalDate: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.OPERATIONAL_DATE,
    displayName: 'Operational Date',
    programManagerDisplayName: 'Operational Date',
    description: NotificationDescriptionEnum.OperationalDate,
    iconUrl: 'operational-date.svg',
    active: true,
    programManagerToggleButton: false,
    notificationPreferenceOptions: false,
  });
  static overGeneration: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.OVER_GENERATION,
    displayName: 'Over Generation',
    programManagerDisplayName: 'Over Generation',
    description: NotificationDescriptionEnum.OverGeneration,
    iconUrl: 'over-generation.svg',
    active: true,
    programManagerToggleButton: true,
    notificationPreferenceOptions: true,
  });
  static paymentOverdue: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.PAYMENT_OVERDUE,
    displayName: 'Payment Overdue',
    programManagerDisplayName: 'Payment Overdue',
    description: NotificationDescriptionEnum.PaymentOverdue,
    iconUrl: 'payment.svg',
    active: true,
    programManagerToggleButton: false,
    notificationPreferenceOptions: false,
  });
  static ptoAtFullOutput: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.PTO_AT_FULL_OUTPUT,
    displayName: 'PTO at Full Output',
    programManagerDisplayName: 'PTO at Full Output',
    description: NotificationDescriptionEnum.PTOAtFullOutput,
    iconUrl: 'pto-at-full-output.svg',
    active: true,
    programManagerToggleButton: false,
    notificationPreferenceOptions: false,
  });
  static recloserStatusChange: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.RECLOSER_STATUS_CHANGE,
    displayName: 'Recloser Status Change',
    programManagerDisplayName: 'Recloser Open',
    description: NotificationDescriptionEnum.RecloserStatusChange,
    iconUrl: 'letter-r.svg',
    active: true,
    programManagerToggleButton: true,
    notificationPreferenceOptions: true,
  });
  static setpointCompliance: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.SETPOINT_COMPLIANCE,
    displayName: 'Set Point Compliance',
    programManagerDisplayName: 'Set Point Compliance',
    description: NotificationDescriptionEnum.SetPointCompliance,
    iconUrl: 'setpoint_compliance.svg',
    active: true,
    programManagerToggleButton: false,
    notificationPreferenceOptions: false,
  });
  static vendorChange: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.VENDOR_CHANGE,
    displayName: 'Vendor Change',
    programManagerDisplayName: 'Vendor Change',
    description: NotificationDescriptionEnum.VendorChange,
    iconUrl: 'vendor-change.svg',
    active: true,
    programManagerToggleButton: false,
    notificationPreferenceOptions: false,
  });
  static userCreatedMessage: NotificationDisplaySpec = new NotificationDisplaySpec({
    key: NotificationDetailsEnum.USER_CREATED_MESSAGE,
    displayName: 'User Created Message',
    programManagerDisplayName: 'User Created Message',
    description: NotificationDescriptionEnum.UserCreatedMessage,
    iconUrl: 'mail-base.svg',
    active: true,
    programManagerToggleButton: true,
    notificationPreferenceOptions: true,
  });
  static allNotificationDisplaySpecs = [
    this.all,
    this.accountStatusChange,
    this.breakerStatusChange,
    this.commercialOperationalDate,
    this.commStatusChange,
    this.disconnectNotice,
    this.noGeneration,
    this.operationalDate,
    this.overGeneration,
    this.paymentOverdue,
    this.ptoAtFullOutput,
    this.recloserStatusChange,
    this.setpointCompliance,
    this.vendorChange,
    this.userCreatedMessage,
  ];

  static getNotificationDisplaySpecsFromNotificationTypes(
    notificationTypes: NotificationDetailsEnum | NotificationDetailsEnum[]
  ): NotificationDisplaySpec | NotificationDisplaySpec[] {
    let notificationTypesIterable = notificationTypes;
    // If passed in notificationTypes is just one, create a list with just that notificationType
    if (typeof notificationTypesIterable === 'string') {
      notificationTypesIterable = [notificationTypesIterable];
    }

    const notificationDisplaySpecs = this.allNotificationDisplaySpecs.filter((x) =>
      notificationTypesIterable?.includes(x['key'] as NotificationDetailsEnum)
    );

    if (notificationDisplaySpecs.length == 1) {
      return notificationDisplaySpecs[0];
    }
    return notificationDisplaySpecs;
  }

  static getAllProgramManagerToggleOptions(): NotificationDisplaySpec[] {
    return this.allNotificationDisplaySpecs.filter((x) => x['programManagerToggleButton']);
  }

  static getAllNotificationPreferenceOptions(): NotificationDisplaySpec[] {
    return this.allNotificationDisplaySpecs.filter((x) => x['notificationPreferenceOptions']);
  }
}
