export enum RepeatInterval {
  Daily = 'Daily',
  Weekly = 'Weekly',
}

export enum EndOption {
  NoEndDate = 'No end date',
  OnThisDay = 'On this day',
  After = 'After',
}

export interface Schedule {
  startDate: Date;
  endDate?: Date;
  repeat: RepeatInterval;
  every: number;
  endOption: EndOption;
  occurrenceCount?: number;
}
