import { getDuration } from '../../utils/date-time.util';
import { Address, AddressModel } from '../address';
import { Contact, ContactModel } from '../contact';
import { Schedule } from '../schedule';
import { Site } from '../site';
import { UserInfo, UserInfoApiModel } from '../userInfo';
import { OutageBase, OutageBaseModel } from './outageBase';
import { OutageDropRetapInfoModel } from './outageDropRetapInfo';

export class PlannedOutage extends OutageBase {
  uuid?: string;
  siteName: string;

  lastEditedBy?: UserInfo;
  lastUpdatedDate?: Date;
  createdBy?: UserInfo;
  createdDate?: Date;

  // for reporting
  uniqueIdDisplay?: string;
  action?: string;
  startDateTimeStr?: string;
  endDateTimeStr?: string;
  createdByStr?: string;
  lastEditedByStr?: string;
  outageEditRoute?: string;
  outageRoute?: string;

  startDateTime?: Date;

  // from Site model
  site?: Site;
  siteType?: string; // (distribution or transmission)

  get duration(): string {
    if (!this.schedule?.startDate) {
      return '';
    }
    return getDuration(this.schedule.startDate, this.schedule.endDate);
  }

  constructor(model: PlannedOutageModel) {
    super(model);
    this.uuid = model.uuid;
    this.siteName = model.site_name;
    this.lastEditedBy = UserInfo.fromApiModel(model.last_edited_by);
    this.lastUpdatedDate = new Date(model.last_updated);
    this.createdBy = UserInfo.fromApiModel(model.created_by);
    this.createdDate = new Date(model.created_date);
    this.uniqueIdDisplay = `${this.uniqueId}`.padStart(6, '0');
  }
}

export class PlannedOutageModel extends OutageBaseModel {
  uuid?: string;
  site_name?: string;

  last_edited_by?: UserInfoApiModel;
  last_updated?: string;
  created_by?: UserInfoApiModel;
  created_date?: string;

  static fromFormValue(
    siteSelection: any,
    outageDetails: any,
    contactList: Contact[]
  ): PlannedOutageModel {
    const contacts = (contactList || []).map((c: Contact) => ({
      name: c.fullName ?? '',
      email: c.emailAddress ?? '',
      phone: c.phoneNumber ?? '',
      alt_phone_number: c.altPhoneNumber ?? undefined,
      contact_type: c.contactType,
      is_primary: !!c.isPrimary,
    })) as ContactModel[];

    const schedule = outageDetails?.schedule as Schedule;
    const dropRetap = outageDetails?.dropRetapInfo
      ? new OutageDropRetapInfoModel(outageDetails?.dropRetapInfo)
      : null;
    const alternateLocation = siteSelection?.alternateLocation
      ? new AddressModel(siteSelection?.alternateLocation as Address)
      : null;

    const outageModel: PlannedOutageModel = {
      unique_id: siteSelection?.uniqueId,
      jurisdiction: siteSelection?.jurisdiction,
      event_type: outageDetails?.outageType,
      outage_type: outageDetails?.outageType,
      additional_comments: siteSelection?.comments ?? '',
      derate_amount: outageDetails?.derateAmount,
      expected_capacity: outageDetails?.temporaryExpectedCapacity,
      installed_capacity: outageDetails?.siteCapacity,
      switching_needed: !!outageDetails?.switchingNeeded,
      work_type: outageDetails?.workType,
      backup_start_date: new Date(schedule?.startDate).toISOString() ?? null,
      backup_end_date: schedule?.endDate ? new Date(schedule?.endDate).toISOString() : null,
      tag_points: outageDetails?.tagPoints ?? '',
      crew_work_location: siteSelection?.crewWorkLocation ?? '',
      contacts: contacts,
      alternate_location: alternateLocation,
      work_description: outageDetails?.workToBePerformed ?? '',

      // schedule fields
      event_start: new Date(schedule?.startDate).toISOString(),
      event_end: schedule?.endDate ? new Date(schedule?.endDate).toISOString() : null,
      repeat_interval: schedule?.repeat,
      cadence: schedule?.every,
      occurrence_count: schedule?.occurrenceCount,
      end_option: schedule?.endOption,
      drop_retap: dropRetap?.drop_retap ?? false,
      drop_date: dropRetap?.drop_date,
      retap_date: dropRetap?.retap_date,
    };

    return outageModel;
  }
}
