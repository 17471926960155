import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitesRoutingModule } from './sites.routing.module';
import { BatteryStorageChartComponent } from './pages/site-specific/battery-storage-chart/battery-storage-chart.component';
import { LoadHistoryChartComponent } from './pages/site-specific/load-history-chart/load-history-chart.component';
import { SiteSpecificComponent } from 'src/app/modules/sites/pages/site-specific/site-specific.component';
import { HeaderComponent } from './pages/site-specific/header/header.component';
import { BatteryHeaderComponent } from 'src/app/modules/sites/pages/site-specific/battery-header/battery-header.component';
import { SiteSearchComponent } from './pages/site-search/site-search.component';
import { CurtailmentsSummaryComponent } from './pages/site-specific/curtailments-component/curtailments-summary.component';
import { CurtailmentChartComponent } from './pages/site-specific/curtailment-chart/curtailment-chart.component';
import { RemoveSiteDialogComponent } from './pages/site-specific/remove-site-dialog/remove-site-dialog.component';
import { DatePipe } from '@angular/common';
import { SitePendingOutagesComponent } from './pages/site-specific/pending-outage-requests/pending-outage-requests.component';

@NgModule({
  declarations: [
    BatteryStorageChartComponent,
    BatteryHeaderComponent,
    LoadHistoryChartComponent,
    SiteSpecificComponent,
    HeaderComponent,
    SiteSearchComponent,
    CurtailmentsSummaryComponent,
    CurtailmentChartComponent,
    RemoveSiteDialogComponent,
    SitePendingOutagesComponent,
  ],
  exports: [BatteryHeaderComponent],
  imports: [SitesRoutingModule, SharedModule],
  providers: [DatePipe],
})
export class SitesModule {}
