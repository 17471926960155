export enum ApprovalStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

export enum ApprovalRoles {
  ECC_OUTAGE_APPROVAL = 'ECC_OUTAGE_APPROVAL',
  FSO_OUTAGE_APPROVAL = 'FSO_OUTAGE_APPROVAL',
  TOC_SOE_OUTAGE_APPROVAL = 'TOC_SOE_OUTAGE_APPROVAL',
}
