import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MatTableDataSource } from '@angular/material/table';
import { Site } from 'src/app/shared/models/site';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { FileService } from 'src/app/shared/services/utils/file.service';
import { endOfDay, lastDayOfMonth, startOfMonth } from 'date-fns';
import { UrlService } from 'src/app/services/url.service';
import { PlannedOutageService } from 'src/app/shared/services/planned-outage.service';
import { Router } from '@angular/router';
import { PlannedOutage } from 'src/app/shared/models/outages/plannedOutage';
import { siteOutagesColumns } from 'src/app/shared/models/outages/outageTableDefaultColumns';
import { InputColumn } from 'src/app/shared/components/table-with-filters/models';
import { TableType } from 'src/app/shared/components/table-with-filters/table-with-filters.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { getDuration } from 'src/app/shared/utils/date-time.util';

@Component({
  selector: 'app-site-planned-outages',
  templateUrl: './site-planned-outages.component.html',
  styleUrls: ['./site-planned-outages.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SitePlannedOutagesComponent extends BaseComponent implements OnInit {
  public columns: InputColumn[] = siteOutagesColumns;
  // Earliest PROD data available
  public minDatePickerFromDate = new Date('JAN 2022');
  public maxDatePickerFromDate = startOfMonth(new Date());
  public minDatepickerThruDate = new Date('JAN 2022');
  public maxDatepickerThruDate = endOfDay(lastDayOfMonth(new Date()));
  public tableType: TableType = 'planned-outages';

  @Input() set site(site: Site) {
    if (site) {
      this.siteModel = site;
      // TODO Assume this should be integrated into GTM.
      this.siteNameForGa = GtmService.format(this.siteModel.name);
    }
  }

  @Input() set outages(outages: PlannedOutage[]) {
    if (outages) {
      this.dataSource.data = outages;
    } else {
      this.dataSource.data = [];
    }
  }

  dataSource = new MatTableDataSource<PlannedOutage | any>([]);
  paginatorSizeOptions = [5, 10, 25, 50, 100];
  siteModel: Site;
  siteNameForGa: string;
  protected readonly GtmService = GtmService;
  private _datePipe = new DatePipe('en-us');
  private _fromDate: Date;
  private _thruDate: Date;

  constructor(
    public fileService: FileService,
    private router: Router,
    private dialog: MatDialog,
    public plannedOutageService: PlannedOutageService,
    public urlService: UrlService,
    private snackBarService: SnackBarService
  ) {
    super();
    setTimeout(() => this.getDataForMonthRange(true));
  }

  ngOnInit(): void {
    this.maxDatePickerFromDate.setFullYear(this.maxDatePickerFromDate.getFullYear() + 1);
    this.maxDatepickerThruDate.setFullYear(this.maxDatepickerThruDate.getFullYear() + 1);
  }

  public getDataForMonthRange(initial = false) {
    // Send analytics event if month range is changed
    if (!initial) {
      // GTM DOC - 5.6.4.1
      GtmService.clickEvent({
        category: 'site',
        action: 'date-range-changed',
        label: 'planned-outages',
      });
    }
    const fromDate = this._fromDate;
    const thruDate = this._thruDate;
    if (thruDate <= fromDate) {
      this.snackBarService.error('Please select valid thru date.');
      return;
    }
    this.plannedOutageService
      .getSitePlannedOutages(this.siteModel.unique_id, fromDate, thruDate)
      .subscribe((outages) => {
        this.dataSource.data = [];
        for (const row of outages) {
          this.dataSource.data.push({
            startDateTimeStr: `${this._datePipe.transform(
              row.schedule.startDate,
              'MM/dd/yy h:mm a'
            )}`,
            endDateTimeStr: row.schedule.endDate
              ? `${this._datePipe.transform(row.schedule.endDate, 'MM/dd/yy h:mm a')}`
              : '--',
            duration: row.schedule.endDate
              ? getDuration(row.schedule.startDate, row.schedule.endDate)
              : '--',
            siteName: row.siteName,
            uniqueId: row.uniqueId,
            uniqueIdDisplay: `${row.uniqueId}`.padStart(6, '0'),
            comments: row.comments,
            siteCapacity: row.siteCapacity,
            derateAmount: row.derateAmount,
            temporaryExpectedCapacity: row.temporaryExpectedCapacity,
            outageType: row.outageType,
            lastEditedByStr: row.lastEditedBy.email,
            lastUpdatedDate: new Date(row.lastUpdatedDate),
            createdByStr: row.createdBy.email,
            createdDate: new Date(row.createdDate),
            uuid: row.uuid,
            switchingNeeded: row.switchingNeeded,
            workType: row.workType,
            tagPoints: row.tagPoints,
            crewWorkLocation: row.crewWorkLocation,
            workToBePerformed: row.workToBePerformed,
          });
        }
      });
  }

  public setFromDate(event: Date) {
    this._fromDate = event;
  }

  public setThruDate(event: Date) {
    this._thruDate = event;
  }

  editPlannedOutage(outageId: string) {
    // GTM DOC - 5.6.4.5.2.1
    GtmService.clickEvent({
      category: 'site',
      action: 'ellipse-|-edit-outage',
      label: 'site-planned-outage',
    });
    this.urlService.setPreviousUrl('/reporting');
    void this.router.navigateByUrl(`/action-center/planned-outages/${outageId}`);
  }
}
