<div>
  <div *ngIf="showForm" class="fixed inset-x-0 inset-y-0 bg-gray-dark opacity-70 z-[2]"></div>
  <div>
      <button (click)="toggleForm()"
              class="visible fixed cursor-pointer border-none bg-transparent p-0 m-0 bottom-1/2 w-[33px] right-0 rotate-90 mr-2 transition ease-in-out duration-900"
              [ngClass]="{'translate-x-40': showForm || loadAtFirst}">
          <div class="rotate-180">
              <span class="bg-white dark:bg-dm-sec-bg shadow-md text-blue dark:text-dm-blue p-10 rounded-t-md whitespace-nowrap">Site Feedback</span>
          </div>
      </button>
  </div>
  <ng-container *ngIf="showForm">
      <form class="h-fit bg-white dark:bg-dm-bg shadow-md rounded-md absolute inset-y-1/4 inset-x-1/3 w-4/12 z-[600]" [formGroup]="feedbackForm">
          <div class="bg-gray-light dark:bg-dm-sec-bg h-48 rounded-t-md flex justify-between">
              <span class="ml-10 block pt-10">Let us know what you think!</span>
              <button (click)="toggleForm()" class="rotate-45 text-2xl justify-self-end text-blue dark:text-dm-blue">+</button>
          </div>
          <div class="w-auto ml-40 mt-24 grid gap-2 grid-rows-2 pr-32">
              <span class="text-blue dark:text-dm-blue">Please provide your feedback below</span>
              <input type="text" #summary formControlName="summary" required placeholder="Summary" (focus)="checkValid(summary)" (focusout)="checkValid(summary)" class="pl-8 focus:placeholder-transparent border-1 border-gray-dark rounded-md h-32" />
              <textarea #ideas formControlName="ideas" required (focus)="checkValid(ideas)" (focusout)="checkValid(ideas)" maxlength="500"  placeholder="Share your ideas" class="pl-8 min-w-0 focus:placeholder-transparent border-1 border-gray-dark mt-10 rounded-md h-96"></textarea>
              <div class="flex justify-between">
                  <span class="text-gray">Maximum 500 Characters</span>
                  <span class="text-gray">{{ideas.value.length}}/500</span>
              </div>
              <input type="text" disabled [placeholder]="authService.accountInfo$.value?.fullName" class="pl-8 focus:placeholder-transparent border-1 border-gray-dark my-32 rounded-md h-32">
              <input type="text" disabled [placeholder]="authService.accountInfo$.value?.email" class="pl-8 focus:placeholder-transparent border-1 border-gray-dark rounded-md h-32" />
              <div class="flex flex-row flex-wrap justify-center my-64 min-w-fit">
                  <div></div>
                  <de-button [disabled]="saving" (buttonClick)="toggleForm()">CANCEL</de-button>
                  <de-button type="primary" [disabled]="!formValid || saving" (buttonClick)="submitForm()">SUBMIT</de-button>
                  <div></div>
              </div>
          </div>
      </form>
  </ng-container>
</div>
