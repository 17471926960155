import { Address, AddressModel } from '../address';
import { Contact, ContactModel } from '../contact';
import { EndOption, RepeatInterval, Schedule } from '../schedule';

import { OutageEventType } from './enums/outageEventType';
import { OutageWorkType } from './enums/outageWorkType';
import { OutageDropRetapInfo } from './outageDropRetapInfo';

export class OutageBase {
  comments?: string;
  derateAmount?: number;
  jurisdiction?: string;
  outageType: OutageEventType;
  siteCapacity: number;
  temporaryExpectedCapacity: number;
  uniqueId: number;
  schedule?: Schedule;
  switchingNeeded?: boolean;
  workType?: OutageWorkType;
  tagPoints?: string;
  crewWorkLocation?: string;
  contacts?: Contact[];
  alternateLocation?: Address;
  workToBePerformed?: string;
  backupStartDate?: Date;
  backupEndDate?: Date;
  dropRetapInfo?: OutageDropRetapInfo;
  distributionOrTransmission?: string;

  outageEventType: OutageEventType;

  constructor(model: OutageBaseModel) {
    this.jurisdiction = model.jurisdiction;
    this.uniqueId = model.unique_id;
    this.siteCapacity = model.installed_capacity;
    this.temporaryExpectedCapacity = model.expected_capacity;
    this.derateAmount = model.derate_amount ?? this.siteCapacity - this.temporaryExpectedCapacity;

    this.outageType = model.event_type ?? ((model.outage_type + ' Outage') as OutageEventType);
    this.outageEventType = this.outageType;

    this.comments = model.additional_comments;
    this.switchingNeeded = model.switching_needed;
    this.workType = model.work_type;
    this.backupStartDate = model.backup_start_date ? new Date(model.backup_start_date) : null;
    this.backupEndDate = model.backup_end_date ? new Date(model.backup_end_date) : null;
    this.tagPoints = model.tag_points;
    this.crewWorkLocation = model.crew_work_location;
    this.contacts = model.contacts?.map((c) => new Contact(c)) ?? [];
    this.alternateLocation = model.alternate_location
      ? new Address(model.alternate_location)
      : undefined;
    this.workToBePerformed = model.work_description;

    this.schedule = {
      startDate: new Date(model.event_start),
      endDate: model.event_end ? new Date(model.event_end) : null,
      repeat: (model.repeat_interval as RepeatInterval) ?? RepeatInterval.Daily,
      every: model.cadence ?? 1,
      endOption: (model.end_option as EndOption) ?? EndOption.NoEndDate,
      occurrenceCount: model.occurrence_count ?? 0,
    };

    if (model.drop_retap || model.drop_date || model.retap_date) {
      this.dropRetapInfo = new OutageDropRetapInfo({
        drop_retap: model.drop_retap ?? false,
        drop_date: model.drop_date ?? null,
        retap_date: model.retap_date ?? null,
      });
    }
  }
}

export class OutageBaseModel {
  jurisdiction?: string;
  unique_id: number;
  event_type: OutageEventType;
  outage_type: string;
  installed_capacity: number;
  expected_capacity: number;
  additional_comments?: string;
  derate_amount: number;
  event_start: string;
  event_end: string;
  repeat_interval?: string;
  cadence?: number;
  occurrence_count?: number;
  end_option?: string;
  switching_needed?: boolean;
  work_type?: OutageWorkType;
  backup_start_date?: string;
  backup_end_date?: string;
  tag_points?: string;
  crew_work_location?: string;
  contacts?: ContactModel[];
  alternate_location?: AddressModel;
  work_description?: string;
  drop_retap?: boolean;
  drop_date?: string;
  retap_date?: string;
}
