import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { SiteNotification } from 'src/app/shared/models/sites/siteNotification';
import { Observable } from 'rxjs';
import { SitesService } from 'src/app/shared/services/api/sites.service';
import { DisplayTableComponent } from 'src/app/shared/components/display-table/display-table.component';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AccountManagementService } from 'src/app/modules/account-management/services/account-management.service';
import { NotificationDetailsEnum } from 'src/app/shared/models/siteFlagDetails';
import { LookupService } from '../../../services/lookup/lookup.service';
import { OutboxMessageType } from '../../../models/outboxMessageType';

@Component({
  selector: 'app-site-notifications',
  templateUrl: './site-notifications.component.html',
  styleUrls: ['./site-notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteNotificationsComponent extends BaseComponent {
  @Input() set siteNotifications(notifications: SiteNotification[]) {
    if (notifications) {
      this._notifications = notifications;
      this._notifications.sort(SiteNotificationsComponent.compareByDate);
      this.accountsService.filterNotifications$.next(this._notifications);
    }
  }

  @ViewChild(DisplayTableComponent) tableDisplay: DisplayTableComponent;

  searchText = '';
  protected _notifications: SiteNotification[] = [];
  public msgOptions: OutboxMessageType[] = [];
  constructor(
    protected sitesService: SitesService,
    protected accountsService: AccountManagementService,
    protected lookupService: LookupService
  ) {
    super();
  }

  public getNotifications = (): Observable<SiteNotification[]> => {
    return this.accountsService.filterNotifications$.asObservable();
  };

  ngOnInit(): void {
    this.subscriptions.push(
      this.lookupService.getNewNotificationTypes().subscribe((msgOptions) => {
        this.msgOptions = msgOptions;
      })
    );
  }

  private static compareByDate(a: { date }, b: { date }): number {
    // Adding NOSONAR tag as this is standard array sorting practice (and tested)
    return a.date < b.date ? 1 : a.date > b.date ? -1 : 0; // NOSONAR
  }

  public showUserMessage(type) {
    const message = this.msgOptions.find((x) => x.code === type);
    return `<strong>${message.title}</strong>: ${message.description}`;
  }

  protected readonly NotificationDetailsEnum = NotificationDetailsEnum;
}
