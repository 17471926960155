import {
  DisconnectNotice,
  InterconnectionAlertStatusChange,
  InterconnectionGenerationViolation,
  InterconnectionNotificationDetail,
  NoGenerationViolation,
  UserCreatedMessage,
} from 'src/app/shared/models/interconnection';
import {
  NotificationDetailsEnum,
  NotificationDisplaySpec,
  NotificationDisplaySpecs,
} from '../siteFlagDetails';

export class SiteNotificationDetail {
  notificationType: NotificationDisplaySpec;
  details:
    | DisconnectNotice
    | InterconnectionAlertStatusChange
    | InterconnectionGenerationViolation
    | NoGenerationViolation
    | UserCreatedMessage;

  constructor(data: InterconnectionNotificationDetail) {
    this.notificationType =
      NotificationDisplaySpecs.getNotificationDisplaySpecsFromNotificationTypes(
        data.notification_type as NotificationDetailsEnum
      ) as NotificationDisplaySpec;
    this.details = data.details;
  }
}
