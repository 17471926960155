import { USState } from './enums/usState';

export class Address {
  street: string;
  addressLine2?: string;
  city: string;
  state: USState;
  zipCode: string;
  county: string;
  latitude?: number;
  longitude?: number;

  constructor(model: AddressModel) {
    this.street = model.street_address;
    this.addressLine2 = model.street_address2;
    this.city = model.city;
    this.state = model.state || model.facility_state;
    this.zipCode = model.zip_code;
    this.county = model.county;
    this.latitude = model.latitude;
    this.longitude = model.longitude;
  }
}

export class AddressModel {
  street_address: string;
  street_address2?: string;
  city: string;
  state?: USState;
  facility_state?: USState;
  zip_code: string;
  county?: string;
  latitude?: number;
  longitude?: number;

  constructor(model: Address) {
    this.street_address = model.street;
    this.street_address2 = model.addressLine2 || undefined;
    this.city = model.city;
    this.state = model.state;
    this.zip_code = model.zipCode;
    this.county = model.county;
    this.latitude = model.latitude || undefined;
    this.longitude = model.longitude || undefined;
  }
}
