import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, finalize, tap, catchError } from 'rxjs';
import { ApprovalStatus } from '../models/enums/approvalStatus';
import { OutageRequest, OutageRequestModel } from '../models/outages/outageRequest';
import { PlannedOutageModel } from '../models/outages/plannedOutage';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApprovalQueueService extends ApiService {
  protected _outagesUrl = `${this.apiUrl}/outages/external/requests/`;

  public pendingOutageRequests$ = new BehaviorSubject<OutageRequest[]>([]);
  public outageRequestHistory$ = new BehaviorSubject<OutageRequest[]>([]);
  public isLoadingOutageRequests$ = new BehaviorSubject<boolean>(false);

  public getPendingOutageRequests(
    uniqueId?: number
  ): Observable<OutageRequestModel[] | [OutageRequestModel[], PlannedOutageModel[]]> {
    this.isLoadingOutageRequests$.next(true);

    const params = uniqueId ? ApiService.setHttpParam('unique_id', uniqueId) : null;
    const options = params ? { params } : {};

    return combineLatest([
      this.http.get<OutageRequestModel[]>(this._outagesUrl, options),
      this.http.get<PlannedOutageModel[]>(
        `${this.apiUrl}/outages/?startDate=2020-12-01T05:28:39.563Z&endDate=2099-01-01T04:59:59.999Z`
      ),
    ]).pipe(
      finalize(() => this.isLoadingOutageRequests$.next(false)),
      tap(([requests, outages]) => {
        const pendingRevisions = requests
          .filter((x) => x.approval_status === ApprovalStatus.Pending)
          .map((apiModel) => new OutageRequest(apiModel));

        const history = requests
          .filter((revision) => revision.approval_status !== ApprovalStatus.Pending)
          .map((apiModel) => new OutageRequest(apiModel));

        // Match outage requests to outages and set old values
        [...pendingRevisions, ...history].forEach((request) => {
          const matchedOutage = outages.find(
            (outage) => outage.uuid === request.originalOutageUUID
          );
          if (matchedOutage) {
            request.setCurrentOutageValues(matchedOutage);
          }
        });

        this.pendingOutageRequests$.next(pendingRevisions);
        this.outageRequestHistory$.next(history);
      }),
      catchError((err) => this.handleError('Unable to fetch outage requests.', err))
    );
  }
}
