import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoggingService } from './logging.service';
import { PlannedOutage, PlannedOutageModel } from '../models/outages/plannedOutage';

@Injectable({
  providedIn: 'root',
})
export class PlannedOutageService extends ApiService {
  public plannedOutagesLoading$ = new BehaviorSubject(false);
  public isPlannedOutageLoading$ = new BehaviorSubject(false);
  private readonly _logger = new LoggingService('Dashboard', 'PlannedOutageService');

  public getPlannedOutage(uuid: string): Observable<PlannedOutage> {
    if (this.isPlannedOutageLoading$.getValue()) {
      return null;
    }
    this.isPlannedOutageLoading$.next(true);

    return this.http.get<PlannedOutageModel>(`${this.apiUrl}/outages/${uuid}`).pipe(
      map((resp: PlannedOutageModel) => new PlannedOutage(resp)),
      catchError((err) => this.handleError('Unable to load planned outage', err)),
      finalize(() => this.isPlannedOutageLoading$.next(false))
    );
  }

  public getPlannedOutages(start: string, end: string): Observable<PlannedOutage[]> {
    if (this.plannedOutagesLoading$.getValue()) {
      return null;
    }
    this.plannedOutagesLoading$.next(true);

    const params = ApiService.setStartEndDateParams(start, end);

    return this.http.get<PlannedOutageModel[]>(`${this.apiUrl}/outages/`, { params }).pipe(
      map((resp: PlannedOutageModel[]) =>
        resp.map((plannedOutageModel) => new PlannedOutage(plannedOutageModel))
      ),
      catchError((err) => this.handleError('Unable to fetch planned outages', err)),
      finalize(() => this.plannedOutagesLoading$.next(false))
    );
  }

  public getSitePlannedOutages(
    uniqueId: number | string,
    startDate: Date,
    endDate: Date
  ): Observable<PlannedOutage[]> {
    this.plannedOutagesLoading$.next(true);
    let params = ApiService.setStartEndDateParams(startDate, endDate);
    params = ApiService.setHttpParam('unique_id', uniqueId, params);

    return this.http.get<PlannedOutageModel[]>(`${this.apiUrl}/outages/`, { params }).pipe(
      map((resp) => resp.map((x) => new PlannedOutage(x))),
      catchError((err) => {
        return this.handleError(`Unable to load site ${uniqueId} planned outages.`, err);
      }),
      finalize(() => this.plannedOutagesLoading$.next(false))
    );
  }

  savePlannedOutage(plannedOutage: PlannedOutageModel): Observable<PlannedOutageModel> {
    this._logger.info(`savePlannedOutage() given ${JSON.stringify(plannedOutage, null, 2)}`);
    return plannedOutage.uuid
      ? this.http.put<PlannedOutageModel>(
          `${this.apiUrl}/outages/${plannedOutage.uuid}`,
          plannedOutage
        )
      : this.http.post<PlannedOutageModel>(
          `${this.apiUrl}/outages/external/requests`,
          plannedOutage
        );
  }
}
