export class OutageDropRetapInfo {
  requestDropRetap: boolean;
  dropDate?: Date;
  retapDate?: Date;

  constructor(model: OutageDropRetapInfoModel) {
    this.requestDropRetap = model.drop_retap;
    this.dropDate = new Date(model.drop_date);
    this.retapDate = new Date(model.retap_date);
  }
}

export class OutageDropRetapInfoModel {
  drop_retap: boolean;
  drop_date?: string;
  retap_date?: string;

  constructor(model: any) {
    this.drop_retap = model.requestDropRetap;
    this.drop_date = model.dropDate ? new Date(model.dropDate as string)?.toISOString() : null;
    this.retap_date = model.retapDate ? new Date(model.retapDate as string)?.toISOString() : null;
  }
}
