import { InputColumn } from 'src/app/shared/components/table-with-filters/models';

// Columns common to both sets
const baseOutagesColumns: InputColumn[] = [
  {
    name: 'Event Start Date',
    key: 'startDateTimeStr',
    type: 'string',
    sticky: true,
    display: true,
    displayFilter: true,
  },
  {
    name: 'Site Installed Capacity (MW)',
    key: 'siteCapacity',
    type: 'string',
    display: true,
    displayFilter: true,
  },
  {
    name: 'Derate Amount',
    key: 'derateAmount',
    type: 'string',
    display: true,
    displayFilter: true,
  },
  {
    name: 'Temporary Expected Capacity',
    key: 'temporaryExpectedCapacity',
    type: 'string',
    display: true,
    displayFilter: true,
  },
  {
    name: 'Restoration Time',
    key: 'endDateTimeStr',
    type: 'string',
    display: true,
    displayFilter: true,
  },
  {
    name: 'Duration (hh:mh)',
    key: 'duration',
    type: 'string',
    display: true,
    displayFilter: true,
  },
  {
    name: 'Event Type',
    key: 'outageType',
    type: 'string',
    display: true,
    displayFilter: true,
  },
  {
    name: 'Comments',
    key: 'comments',
    type: 'string',
    display: true,
    displayFilter: true,
  },
  {
    name: 'Created By',
    key: 'createdByStr',
    type: 'string',
    display: true,
    displayFilter: false,
  },
  {
    name: 'Created Date',
    key: 'createdDate',
    type: 'date',
    display: true,
    displayFilter: false,
  },
  {
    name: 'Last Edited By',
    key: 'lastEditedByStr',
    type: 'string',
    display: true,
    displayFilter: false,
  },
  {
    name: 'Last Edited',
    key: 'lastUpdatedDate',
    type: 'date',
    display: true,
    displayFilter: false,
  },
];

// Additional columns needed by one of the components
const extraSiteColumns: InputColumn[] = [
  {
    name: 'Site Name',
    key: 'outageRoute',
    type: 'html',
    sticky: true,
    display: true,
    displayFilter: true,
    route: 'outageEditRoute',
  },
  {
    name: 'Site ID',
    key: 'uniqueIdDisplay',
    type: 'string',
    display: true,
    displayFilter: true,
  },
];

// Export variations
export const outagesReportingColumns: InputColumn[] = [...extraSiteColumns, ...baseOutagesColumns];

export const siteOutagesColumns: InputColumn[] = [...baseOutagesColumns];
