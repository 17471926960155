import { ContactType } from './enums/contactType';

export class Contact {
  fullName: string;
  phoneNumber: string;
  emailAddress: string;
  altPhoneNumber?: string;
  faxNumber?: string;
  contactType?: ContactType;
  isPrimary?: boolean; // submitter can be a primary contact if this is true
  title?: string;

  constructor(contact?: ContactModel) {
    if (contact) {
      this.fullName = contact?.name && contact.name.length > 0 ? contact.name : 'Not Available';
      this.phoneNumber =
        contact?.phone && contact.phone.length > 0 ? contact.phone : 'Not Available';
      this.emailAddress =
        contact?.email && contact.email.length > 0 ? contact.email : 'Not Available';
      this.contactType = contact.contact_type;
      this.isPrimary = contact.is_primary;
      this.title = contact.title;
      this.faxNumber = contact.fax_number;
      this.altPhoneNumber = contact.alt_phone_number;
    }
  }
}

export class ContactModel {
  name: string;
  email: string;
  phone: string;
  alt_phone_number?: string;
  contact_type?: ContactType;
  is_primary?: boolean;
  fax_number?: string;
  title?: string;

  constructor(contact: Contact) {
    this.name = contact.fullName;
    this.phone = contact.phoneNumber;
    this.email = contact.emailAddress;
    this.contact_type = contact.contactType;
    this.is_primary = contact.isPrimary;
    this.title = contact.title;
    this.fax_number = contact.faxNumber;
    this.alt_phone_number = contact.altPhoneNumber;
  }
}
