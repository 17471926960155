import { Injectable } from '@angular/core';
import { AllowedAppRoleEnum } from '../enums/roles.enum';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  EndSessionRequest,
  EventMessage,
  InteractionStatus,
} from '@azure/msal-browser';
import { LoggingService } from 'src/app/shared/services/logging.service';
import { BehaviorSubject } from 'rxjs';
import { UserInfo } from 'src/app/shared/models/userInfo';

export const AllowedAppRoleSet = Object.values(AllowedAppRoleEnum);
export type AllowedAppRole = (typeof AllowedAppRoleSet)[number];
export const DeniedAppRoleSet = ['FERC_NOT_AUTHORIZED'] as const;
export type DeniedAppRole = (typeof DeniedAppRoleSet)[number];

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  public activeUser: UserInfo;
  public accountInfo$ = new BehaviorSubject<UserInfo>(null);
  private _msalLogger = new LoggingService('AppComponent', 'msal');

  constructor(private broadcastService: MsalBroadcastService, private msalService: MsalService) {}

  setUserInfo() {
    // MSAL interactions
    this.broadcastService.inProgress$.subscribe((status: InteractionStatus) => {
      this._msalLogger.debug('broadcastService.inProgress$', status);
    });

    this.broadcastService.msalSubject$.subscribe((evt: EventMessage) => {
      this._msalLogger.debug('broadcastService.msalSubject$', evt);
      if (evt.eventType === 'msal:acquireTokenSuccess' || evt.eventType === 'msal:loginSuccess') {
        this.msalService.instance.setActiveAccount(evt.payload['account'] as AccountInfo);
        // Only show feedback tab and latest updates to user once they are signed in and have access
        const userInfo = this.msalService.instance.getActiveAccount()?.idTokenClaims;
        this.activeUser = {
          firstName: userInfo?.given_name ? (userInfo?.given_name as string) : '',
          lastName: userInfo?.family_name ? (userInfo?.family_name as string) : '',
          fullName: `${userInfo?.given_name} ${userInfo?.family_name}`,
          email: userInfo?.emails[0] ? userInfo?.emails[0] : '',
        };
        this.accountInfo$.next(this.activeUser);
      }
    });
  }

  logout() {
    const logoutReq: EndSessionRequest = {
      // Should invalidate the specific token obtained for this account within this app
      idTokenHint: this.msalService.instance.getActiveAccount().idToken,
    };
    this.msalService.logoutRedirect(logoutReq);
  }
}
