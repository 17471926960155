export class UserInfo {
  constructor(
    public email: string,
    public firstName: string,
    public lastName: string,
    public fullName: string
  ) {}

  static fromApiModel(model: UserInfoApiModel): UserInfo {
    if (!model) {
      return new UserInfo('', '', '', '');
    }
    return new UserInfo(
      model.email,
      model.first_name,
      model.last_name,
      `${model.first_name} ${model.last_name}`
    );
  }
}

export class UserInfoApiModel {
  email: string;
  first_name: string;
  last_name: string;

  constructor(model: UserInfo) {
    this.email = model.email;
    this.first_name = model.firstName;
    this.last_name = model.lastName;
  }
}
